import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Menu from './menu';

import "../../style/index.scss"
import "./layout.scss"

/* const LOGO_QUERY = graphql`
  query {
    logoImage: file(relativePath: { eq: "logo-text-trans-2.png" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`; */

const Layout = ({ children, showMenu, fullPage }) => {
  // const data = useStaticQuery(LOGO_QUERY)

  const headerClassNames = [];
  headerClassNames.push('bl-header');
  if (fullPage) {
    headerClassNames.push('bl-header--full-page');
  }

  React.useEffect(() => {
    if (document) {
      const WOW = require('wowjs').WOW;
      new WOW().init();
    }
  });

  return (
    <>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showMenu: PropTypes.bool.isRequired,
  fullPage: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  showMenu: true,
  fullPage: false,
}


export default Layout
